import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    private meta: Meta,
    private title: Title) {
    this.setMetaTags();
  }

  setMetaTags() {
    this.title.setTitle('Conversor de Extratos para OFX');
    this.meta.addTags([
      {
        name: 'description',
        content: 'Use nosso Conversor de Extrato PDF para transformar seus arquivos PDF em extratos de forma rápida e fácil.'
      },
      {
        name: 'keywords',
        content: 'Conversor de Extrato PDF, Transformar PDF em OFX, Extrato Bancário PDF, PDF para OFX, CSV para OFX, Extrato Bancário para OFX, Excel para OFX, Xls para OFX, Html para OFX'
      },
      {
        name: 'author',
        content: 'Epígrafo Soluções e Serviços'
      }, { name: 'robots', content: 'index, follow' }]);
  }
}
