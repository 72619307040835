// Components do sistema
import { HttpHeaders } from '@angular/common/http';
import { accessTokenAsaas, accessTokenConversor } from '../api';
import { LoginAccessV1Model } from 'src/app/auth/models/login-access-v1.model';

export class SecurityUtil {
  public static update = null;

  public static set(
    account: LoginAccessV1Model,
    token: string
  ) {
    localStorage.setItem('ofx-account', btoa(JSON.stringify(account)));
    localStorage.setItem('ofx-token', token);
  }

  public static setAccount(account: LoginAccessV1Model) {
    localStorage.setItem('ofx-account', btoa(JSON.stringify(account)));
  }

  public static setToken(token: string) {
    localStorage.setItem('ofx-token', token);
  }

  public static getAccount(): LoginAccessV1Model | null {
    const data = JSON.parse(atob(localStorage.getItem('ofx-account') || ''));
    const account: LoginAccessV1Model = data;
    if (data) {
      return account;
    } else {
      return null;
    }
  }

  public static getToken(): string | null {
    const data = localStorage.getItem('ofx-token');
    if (data) {
      return data;
    } else {
      return null;
    }
  }

  public static hasToken(): boolean {
    if (this.getToken())
      return true;
    else
      return false;
  }

  public composeHeader() {
    const token = localStorage.getItem('ofx-token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return headers;
  }

  public composeHeaderAsaas() {
    const headers = new HttpHeaders().set('token', accessTokenAsaas);
    return headers;
  }

  public composeHeaderConversor() {
    const headers = new HttpHeaders().set('accesstoken', accessTokenConversor);
    return headers;
  }

  public static clear() {
    localStorage.removeItem('ofx-token');
    localStorage.removeItem('ofx-account');
    localStorage.removeItem('userAccount');
  }
}
